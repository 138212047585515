import React from "react";
import Snake from "../images/snake.svg";
import Youtube from "../images/socials/youtube.svg";
import TikTok from "../images/socials/tiktok.svg";
import Facebook from "../images/socials/facebook.svg";
import Insta from "../images/socials/instagram.svg";
import ScrollIntoView from "react-scroll-into-view";

function Footer() {
  return (
    <div className="footer">
      <div className="social-links">
        <a
          href="https://www.youtube.com/@happybasins"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Youtube} className="social-icon" alt="youtube icon"></img>
        </a>
        <a
          href="https://www.tiktok.com/@happybasins"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TikTok} className="social-icon" alt="tiktok icon"></img>
        </a>
        <a
          href="https://www.facebook.com/amybenzi"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} className="social-icon" alt="facebook icon"></img>
        </a>
        <a
          href="https://www.instagram.com/happybasins"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Insta} className="social-icon" alt="instagram icon"></img>
        </a>
      </div>
      <ScrollIntoView selector="#home">
        <img src={Snake} className="footer-icon" alt="snake icon"></img>
      </ScrollIntoView>
      <div>
        <a href="https://www.benziholler.com/" target="_blank" rel="noreferrer">
          <div>
            <div>Design and code by</div>
            <div> Benzi Holler</div>
            <div> 2024 &copy;</div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer;
